/**
 * index.jsx
 *
 * @file Schulungen page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import Divider from "@mui/material/Divider"
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql } from '@apollo/client'
import { graphql } from 'gatsby'
import React from 'react'
import Typography from '@mui/material/Typography'

// internal imports
import { EventsGallery } from '../../components/EventsGallery'
import { Link } from '../../components/Link'
import { MailingList } from "../../components/MailingList"

/**
 * This component represents the Schulungen page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const SchulungenPage = ({ data }) => {
	/** Query to get the current events from the server. */
	const GET_ALL_EVENTS = gql`
		query AllEvents(
			$fee: Boolean
			$registrationPeriod: Date
			$page: Int
			$regionalNetwork: String
			$startAfter: Date
			$startBefore: Date
			$type: String
		) {
			eventsWithoutSecondDates(
				fee: $fee
				first: 9
				initialRegistrationPeriodBefore: $registrationPeriod
				page: $page
				periodOfRestBefore: $registrationPeriod
				regionalNetwork: $regionalNetwork
				secondRegistrationPeriodBefore: $registrationPeriod
				startAfter: $startAfter
				startBefore: $startBefore
				sort: [
					{ column: "created_at" order: DESC }
				]
				type: $type
			) {
				data {
					creator {
						id
						name
					}
					end
					eventID
					fee
					htmlLink
					id
					initialRegistrationPeriod
					lecturer {
						id
						name
					}
					lecturerFallback
					periodOfRest
					regionalNetworks
					secondDate {
						end
						id
						start
					}
					secondRegistrationPeriod
					start
					status
					summary
					type {
						description
						type
					}
					venue {
						city
						id
						location
						name
						osm
					}
				}
				paginatorInfo {
					count
					currentPage
					firstItem
					hasMorePages
					lastItem
					lastPage
					perPage
					total
				}
			}
		}
	`

	return (
		<>
			<GatsbySeo title="Schulungen" />
			<Typography align="center" color="primary" gutterBottom variant="h4">Schulungen</Typography>
			<Box sx={{ my: 5 }}>
				<Typography align="center" color="secondary" sx={{ mb: 1 }} variant="h5">Alle Schulungen</Typography>
				<Typography paragraph variant="body2">Im folgenden finden Sie eine Übersicht über alle unserer Schulungen. Weiter unterhalb finden Sie weiterführende Informationen und Links zu den einzelnen Veranstaltungstypen.</Typography>
				<EventsGallery
					EVENTS_QUERY={ GET_ALL_EVENTS }
					filterable
					identifier="eventsWithoutSecondDates"
					noEventsMessage="Zur Zeit gibt es keine Veranstaltungen, zu denen Sie sich oder Ihre Kolleginnen bzw. Kollegen anmelden können." />
			</Box>
			<Typography paragraph>Jugend debattiert NRW organisiert im Auftrag der Projektpartner verschiedene Schulungen:</Typography>
			{ data.allEventType.nodes.map( eventType => (
				<React.Fragment key={ eventType.id }>
					<Typography color="secondary" variant="subtitle1">{ eventType.description }</Typography>
					{ eventType.information && <Typography variant="body2">{ eventType.information }</Typography> }
					<Typography paragraph variant="body2">
						<Link to={ eventType.type }>Zum aktuellen Angebot <FontAwesomeIcon icon={ faLink } size="sm" /></Link>
					</Typography>
				</React.Fragment>
			) ) }
			<Divider />
			<MailingList />
		</>
	)
}

export default SchulungenPage

export const query = graphql`{
	allEventType {
		nodes {
			description
			id
			information
			type
		}
  	}
}
`
