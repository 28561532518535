/**
 * MailingList.jsx
 *
 * @file This component creates a small form to subscribe/unsubscribe to/from
 * the mailing list.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import React from "react"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

/**
 * This component renders the form to subscribe/unsubscribe to/from the mailing
 * list.
 *
 * @returns {Node} The rendered form.
 */
const MailingList = () => (
	<>
		<Typography paragraph variant="body2">Gerne halten wir Sie über unsere Angebote auf dem Laufenden. Um Informationen per E-Mail zu erhalten, können Sie sich in unsere Mailingliste eintragen:</Typography>
		<Box
			action="https://ml.kundenserver.de/cgi-bin/mailinglist.cgi"
			component="form"
			method="POST"
			target="_blank">
			<FormControl fullWidth>
				<FormLabel id="mailing-list-subscription">Abonnement</FormLabel>
				<RadioGroup
					aria-labelledby="mailing-list-subscription"
					defaultValue="subscribe"
					name="subscribe_r">
					<FormControlLabel control={<Radio />} label={<Typography variant="body2">Ja, ich möchte mich auf die Mailingliste für Informationen zu den Schulungen von Jugend debattiert eintragen lassen - infoschulungen@jugend-debattiert-nrw.de.</Typography>} value="subscribe" />
					<FormControlLabel control={<Radio />} label={<Typography variant="body2">Bitte streichen Sie mich wieder von der Mailingliste infoschulungen@jugend-debattiert-nrw.de.</Typography>} value="unsubscribe" />
				</RadioGroup>
			</FormControl>
			<FormControl fullWidth>
				<TextField
					helperText="Geben Sie Ihre E-Mail-Adresse ein"
					inputProps={{
						maxlength: 51,
						size: 51,
					}}
					label="E-Mail-Adresse"
					name="mailaccount_r" />
				<TextField
					helperText="Wiederholen Sie die eingegebene E-Mail-Adresse"
					inputProps={{
						maxlength: 51,
						size: 51,
					}}
					label="E-Mail-Adresse"
					name="mailaccount2_r" />
			</FormControl>
			<FormControl fullWidth sx={{ flexDirection: "row", justifyContent: "center" }}>
				<Button type="SUBMIT" value="Absenden">Absenden</Button>
				<Button type="RESET" value="Zurücksetzen">Zurücksetzen</Button>
				<input name="FBMLNAME" type="hidden" value="infoschulungen@jugend-debattiert-nrw.de" />
				<input name="FBLANG" type="hidden" value="de" />
				<input name="FBURLERROR_L" type="hidden" value="https://ml.kundenserver.de/mailinglist/error.de.html" />
				<input name="FBURLSUBSCRIBE_L" type="hidden" value="https://ml.kundenserver.de/mailinglist/subscribe.de.html" />
				<input name="FBURLUNSUBSCRIBE_L" type="hidden" value="https://ml.kundenserver.de/mailinglist/unsubscribe.de.html" />
				<input name="FBURLINVALID_L" type="hidden" value="https://ml.kundenserver.de/mailinglist/invalid.de.html" />
			</FormControl>
		</Box>
	</>
)

export default MailingList
